import styled from "styled-components";

export const ListWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: ${({ centered }) => (centered ? "center" : "flex-start")};
	flex-wrap: wrap;
	align-items: stretch;
	padding: 0;
	margin: 0;
	column-gap: ${(props) => props.gap};
	row-gap: 1rem;

	& > * {
		margin: 0;
	}
`;

export const Flow = styled.div`
	display: flex;
	flex-direction: column;
	align-items: ${({ centered }) => (centered ? "center" : "flex-start")};
	& > *:not(:last-child) {
		margin-bottom: 1rem;
	}
`;
