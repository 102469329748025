import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Separator, Text } from "@website-builder/ui/shared/elements";

import { ListWrapper, Flow } from "./styles";
import { MOBILE_L_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import { useMediaQuery } from "@react-hook/media-query";

const HorizontalList = forwardRef(
	(
		{
			listItems,
			gap,
			separator,
			separatorColor,
			separatorOpacity,
			maxItems,
			labelConfig,
			centered = false,
			...restProps
		},
		ref,
	) => {
		const isMobile = useMediaQuery(`(max-width: ${MOBILE_L_BP}px)`);
		const [maxItemsInRow, setMaxItemsInRow] = useState(maxItems);

		useEffect(() => {
			if (isMobile) {
				setMaxItemsInRow(0);
			} else {
				setMaxItemsInRow(maxItems);
			}
		}, [isMobile]);

		const text = labelConfig.hasOwnProperty("text") ? labelConfig.text : "";
		const variant = labelConfig.hasOwnProperty("variant")
			? labelConfig.variant
			: "label_M";
		const color = labelConfig.hasOwnProperty("color")
			? labelConfig.color
			: "var(--secondary-gray-dark)";

		if (maxItemsInRow && maxItemsInRow > 0) {
			let content = [];
			let rowNo = 0;
			for (
				rowNo = 0;
				rowNo < Math.ceil(listItems.length / maxItemsInRow);
				rowNo++
			) {
				let rowContent = [];
				for (let itemInRow = 0; itemInRow < maxItemsInRow; itemInRow++) {
					if (itemInRow + rowNo * maxItemsInRow === listItems.length) break;
					rowContent.push(
						<React.Fragment key={itemInRow}>
							{listItems[itemInRow + rowNo * maxItemsInRow]}
						</React.Fragment>,
					);
					if (
						separator &&
						itemInRow + rowNo * maxItemsInRow + 1 !== listItems.length &&
						itemInRow + 1 != maxItemsInRow
					) {
						rowContent.push(
							<Separator
								vertical
								color={separatorColor}
								opacity={separatorOpacity}
								key={itemInRow + "-separator"}
							/>,
						);
					}
				}
				content.push(
					<ListWrapper gap={gap} key={rowNo} centered={centered}>
						{rowContent}
					</ListWrapper>,
				);
			}
			return (
				<Flow ref={ref} {...restProps} centered={centered}>
					{text && (
						<Text
							variant={variant}
							style={{
								display: "block",
								color: color,
							}}
						>
							{text}
						</Text>
					)}
					{content}
				</Flow>
			);
		}
		return (
			<Flow ref={ref} {...restProps} centered={centered}>
				{text && (
					<Text variant={variant} style={{ display: "block", color: color }}>
						{text}
					</Text>
				)}
				<ListWrapper gap={gap} centered={centered}>
					{listItems.map((item, index) => (
						<React.Fragment key={index}>
							{item}
							{separator && index + 1 !== listItems.length && (
								<Separator
									vertical
									color={separatorColor}
									opacity={separatorOpacity}
								/>
							)}
						</React.Fragment>
					))}
				</ListWrapper>
			</Flow>
		);
	},
);

HorizontalList.defaultProps = {
	listItems: [],
	gap: "1.5rem",
	maxItems: 0,
	labelConfig: {},
};

HorizontalList.propTypes = {
	listItems: PropTypes.array,
	gap: PropTypes.string,
	color: PropTypes.string,
	opacity: PropTypes.string,
	maxItems: PropTypes.number,
};

export default HorizontalList;
